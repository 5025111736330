
import React from "react"
import MetaData from '../../components/metaData'
import Header from '../../components/header';
import PageTracking from "../../components/PageTracking";
import ContentsContiner from "../../components/contentContiner";
import { Typography } from "@material-ui/core";
import Simiration from "./simulation"
import Status from "./status";
import Price from "./price";
import Flow from "./flow";

const  Request = () => {

  return (
      <>
        <MetaData page='request' />
        <Header visibleHome={true} />
        <PageTracking page='/request' />

        <ContentsContiner>
          <Typography variant="h3" gutterBottom>
            request.
          </Typography>
          <Typography variant="body1" component="div">
            ご依頼について
          </Typography>
          <Status />
          <Price />
          <Simiration />
          <Flow />
        </ContentsContiner>
      </>
  )}

export default Request;
