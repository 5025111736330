import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Card, CardContent, Tabs, Tab, Link } from "@material-ui/core";
import { OpenInNewRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        '&::before': {
          content: '"■"',
          paddingRight: '4px',
          color: theme.palette.secondary.main,
          fontSize: '2em',
        }
      },
      box: {
        width: '100%',
        marginTop: '40px'
      },
      planGlid: {
        marginTop: '20px',
      },
      priceGlid: {
        textAlign: 'right',
        fontWeight: 'bold',
      }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Price = () => {
  const classes = useStyles();
  const [tabName, setTabName] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabName(newValue);
  };

  return (
    <Box className={classes.box}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
          料金表
        </Typography>
        <Tabs value={tabName} onChange={handleChange} centered>
          <Tab label="イラスト" />
          <Tab label="Live2D" />
          <Tab label="ループアニメーション" />
        </Tabs>
        <TabPanel value={tabName} index={0}>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>アイコン制作（正方形）</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>8,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>簡易アニメ塗り<Link href="https://coconala.com/services/3144807" target="_brank">※ココナラ参照<OpenInNewRounded fontSize="small" /></Link></Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>3,000〜5,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>通常イラスト制作</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>15,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>＋背景付き</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>10,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>＋ラフパターン追加（1パターン毎に）</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>2,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>＋お急ぎ（納品希望日3週間以内）</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>5,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        </TabPanel>
        <TabPanel value={tabName} index={1}>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>キャラデザ</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>10,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>パーツ分けイラスト制作</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>20,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>三面図</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>10,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>モデリング</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>40,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>＋帽子や王冠など</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>5,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>＋小物</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>5,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>＋ペット</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>10,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>＋衣装差分</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>10,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs></Grid>
          <Grid item xs={10} md={8}>
            <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
              モデリング料金内に含まれるもの
            </Typography>
            ・頭の角度 XYZ<br/>
            ・体の角度 XYZ<br/>
            ・呼吸<br/>
            ・瞳の開閉<br/>
            ・口の開閉<br/>
            ・目の見開き<br/>
            ・笑顔<br/>
            ・口の変形<br/>
            ・あいうえお口<br/>
            ・髪の揺れ XYZ<br/>
            ・衣装の揺れ<br/>
            ・表情モーションの追加（6つまで相談可能）<br/><br/>
            その他書かれていないことについても、随時ご相談ください。<br/>
            イラストのみや、モデリングのみのご依頼も可能です。<br/>
            ご依頼時点で、パーツ分けイラストがない場合でもモデリングのご予約は可能です。<br/>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        </TabPanel>
        <TabPanel value={tabName} index={2}>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>ループあり</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>18,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.planGlid}>
          <Grid item xs></Grid>
          <Grid item xs={7} md={6}>ループアなし</Grid>
          <Grid item xs={3} md={2} className={classes.priceGlid}>15,000円</Grid>
          <Grid item xs></Grid>
        </Grid>
        </TabPanel>
      </CardContent> 
    </Card>
  </Box>
  );
}
export default Price;
