import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        '&::before': {
          content: '"■"',
          paddingRight: '4px',
          color: theme.palette.secondary.main,
          fontSize: '2em',
        }
      },
      box: {
        width: '100%',
        marginTop: '40px'
      },
}));

const Status = () => {
  const classes = useStyles();

  return (
  <Box className={classes.box}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
          受付状況
        </Typography>
        <Typography variant="body1" paragraph>
          ご依頼状況はSNSなどで都度告知しております。
          受付は随時しておりますので、お見積もり等お気軽にご相談いただければ嬉しいです。<br/>
          <br/>
          ・一枚絵イラスト（歌ってみた動画、朗読動画、小説の表紙・挿絵など）<br/>
          ・Live2D（キャラクターデザインからモデリングまで一貫して行います。）<br/>
          ・ループアニメーション（YouTubeの待機画面など）<br/>
        </Typography>
      </CardContent> 
    </Card>
  </Box>
  );
}
export default Status;
