import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Card, CardContent, Link } from "@material-ui/core";
import { KeyboardArrowDownRounded, OpenInNewRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        '&::before': {
          content: '"■"',
          paddingRight: '4px',
          color: theme.palette.secondary.main,
          fontSize: '2em',
        }
      },
      box: {
        width: '100%',
        marginTop: '40px'
      },
      arrow: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: '60px',
        background: theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px 0px'
    
      },
      requestFlow: {
        padding: theme.spacing(1),
        margin: '0 0 0 0 auto',
        border: 'solid 1px',
        borderRadius: '5px',
        borderColor: theme.palette.secondary.main,
    
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(70),
        },
      },
      hr: {
        width: '50%',
        borderTop: '1px',
        borderBottom: 'none',
        borderStyle: 'solid',
        marginTop: '40px',
        marginBottom: '40px',
        borderColor: theme.palette.primary.main,
      },
}));

const Flow = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
          ご依頼の流れ
        </Typography>
        <Typography variant="body1" paragraph>
          詳細な打ち合わせが<b>必要ない</b>リクエストについては、
          <Link href="https://skeb.jp/@meru_lotte" target="_brank">Skeb<OpenInNewRounded fontSize="small" /></Link>
          をご利用ください。<br/>
          それ以外については、以下をご確認ください。<br/><br/>
        </Typography>
        <Grid 
          container
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Grid item className={classes.requestFlow}>
            <Typography variant="subtitle1" gutterBottom>
              ご連絡
            </Typography>
            <Typography variant="body1" paragraph>
              X(Twitter)DM、Discord(めるろって id:meru_lotte)、<br/>
              または メール(meru_lotte@einemeru.com)にてご連絡ください。<br/>
              また、コミッションサイト
              <Link href="https://coconala.com/users/2413277" target="_brank">ココナラ<OpenInNewRounded fontSize="small" /></Link>
              も登録がありますので併せてご利用ください。<br/>
              ※24時間以内に返信いたします。<br/>
              ※料金表がココナラと同期できていません。HPとココナラで相違ありますがご予算を超える場合には相談に応じますのでその旨お伝えください。
            </Typography>
          </Grid>
          <Grid item className={classes.arrow}>
            <KeyboardArrowDownRounded />
          </Grid>
          <Grid item className={classes.requestFlow}>
            <Typography variant="subtitle1" gutterBottom>
              お見積もり / スケジュール確認
            </Typography>
            <Typography variant="body1" paragraph>
              ご依頼内容について確認し、「見積もり」と「スケジュール」を相談します。
            </Typography>
          </Grid>
          <Grid item className={classes.arrow}>
            <KeyboardArrowDownRounded />
          </Grid>
          <Grid item className={classes.requestFlow}>
            <Typography variant="subtitle1" gutterBottom>
              お支払い
            </Typography>
            <Typography variant="body1" paragraph>
            「見積もり」と「スケジュール」が確定しましたらお支払いをお願いいたします。<br/>
            高額な場合は、ラフ制作前後で分割お支払いも可能です。<br/>
            3日以上お支払いがない場合は、スケジュールに影響が出てしまうためキャンセル扱いとなる場合もございますのでご注意ください。
            </Typography>
            <Typography variant="body1" paragraph>
              【お支払い方法】<br/>
              ココナラの場合はココナラ上でお支払い<br/>
              それ以外は<Link href="https://azkari.jp/" target="_brank">アズカリ<OpenInNewRounded fontSize="small" /></Link>でお願いしております。
            </Typography>
          </Grid>
          <Grid item className={classes.arrow}>
            <KeyboardArrowDownRounded />
          </Grid>
          <Grid item className={classes.requestFlow}>
            <Typography variant="subtitle1" gutterBottom>
              ラフ制作
            </Typography>
            <Typography variant="body1" paragraph>
            お支払いの確認ができ次第、ラフの制作に入ります。<br/>
            作成したラフについて、納得のいくまで対応させていただきます。
            </Typography>
          </Grid>
          <Grid item className={classes.arrow}>
            <KeyboardArrowDownRounded />
          </Grid>
          <Grid item className={classes.requestFlow}>
            <Typography variant="subtitle1" gutterBottom>
              本制作
            </Typography>
            <Typography variant="body1" paragraph>
            ラフが問題なければ本制作に入ります。<br/>
            本制作後に追加でお日にちが必要なくらい大きな修正が入った場合には、追加で料金をいただく場合がございます。
            あらかじめご了承ください。
            </Typography>
          </Grid>
          <Grid item className={classes.arrow}>
            <KeyboardArrowDownRounded />
          </Grid>
          <Grid item className={classes.requestFlow}>
            <Typography variant="subtitle1" gutterBottom>
              納品
            </Typography>
            <Typography variant="body1" paragraph>
            問題なければsampleの透かしを外し、データをお渡しし納品完了となります。<br/>
            </Typography>
          </Grid>
        </Grid>
        <hr className={classes.hr} />

        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
          注意事項、お受けできない依頼について
        </Typography>
        <Typography variant="subtitle2" >
          二次創作物について
        </Typography>
        <Typography variant="body1" paragraph>
          トラブルを避けるため、基本的に二次創作のご依頼はお受けしておりません。<br/>
          公式から二次創作の許可が出ている場合には、そのソースとなるものをご依頼時にご提示ください。<br/>
          VTuberまたはそれに準ずる活動者様についてはご本人様からの依頼であれば問題ございません。<br/>
          ファンの方からの依頼についてはご本人様からの許可がある場合のみOKとさせていただきます。
        </Typography>
        <Typography variant="subtitle2" >
          R指定のつくイラスト
        </Typography>
        <Typography variant="body1" paragraph>
          年齢制限を設けなければいけないイラストについてはお受けできません。<br/>
        ご了承ください。
        </Typography>
        <Typography variant="subtitle2" >
          著作権について
        </Typography>
        <Typography variant="body1" paragraph>
          特段の取り決めがない限り、著作権は譲渡しておりません。<br/>
          使用範囲外でのイラストの利用についてはご相談ください。
        </Typography>
        <Typography variant="subtitle2" >
          商用利用について
        </Typography>
        <Typography variant="body1" paragraph>
          商用利用可能です。<br/>
          グッズ制作などを予定している場合にはその旨をご連絡ください。
        </Typography>
        <hr className={classes.hr} />

        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
          テンプレート
        </Typography>
        <Typography variant="body1" paragraph>
          ご依頼の際、ご活用いただければと思います。<br/>
          ※特にこのフォーマットでなければいけないというわけではありません。
        </Typography>

        <Typography variant="body1" paragraph>
        【概要】<br/>
        【希望納期】<br/>
        【予算】<br/>
        【詳細】<br/>
          ・キャラクターデザインの有無<br/>
          ・背景の有無<br/>
          ・差分の有無<br/>
        【使用用途 / 範囲】<br/>
        【データ形式】<br/>
        【サイズ】<br/>
        【制作物の公表 / 実績への記載許可】<br/>
        </Typography>
      </CardContent>  
    </Card>
  </Box>

  );
}
export default Flow;
